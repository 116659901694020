import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";

const CalcCauntPrice = () => {
  const { calculator } = useSelector((state) => state.calculator);
  const { translations } = useSelector((state) => state.language);

  const [calcEmail, setCalcEmail] = useState("");
  const [calcPhoneNumber, setCalcPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    console.log(e.target);
    if (e.target && e.target.name === "email") {
      setCalcEmail(e.target.value);
    } else if (e.target && e.target.name === "phoneNumber") {
      setCalcPhoneNumber(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if ((calcEmail, calcPhoneNumber)) {
      fetch("https://backend-for-ohm-energy-test.vercel.app/send-calc-info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: calcEmail,
          phoneNumber: calcPhoneNumber,
          ...calculator,
        }),
      })
        .then((response) => response.text())
        .then((data) => {
          setIsLoading(false);
          alert(data);
        })
        .catch((error) => {
          alert("Error sending email");
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
  };

  const handleClick = (e) => {
    const calcContainer = document.querySelector(".calc-count-price-container");
    if (e.target.closest(".calc-count-price-inner-container") === null) {
      calcContainer.style.display = "none";
      document.querySelector(".App").style.filter = "blur(0px)";
    }
  };

  return (
    <div className="calc-count-price-container" onClick={handleClick}>
      <div className="blur-background"></div>
      <div className="calc-count-price-inner-container py-5 px-3">
        <div>
          <h4 className="text-center mb-4">{translations.sendCalcDataTitle}</h4>
        </div>
        <div>
          <Form className="request-price-form" onSubmit={handleSubmit}>
            <Form.Floating className="mb-2 request-email bordere-radous-0">
              <Form.Control
                id="request-email-input"
                type="email"
                name="email"
                placeholder="name@example.com"
                value={calcEmail}
                autoComplete="email"
                onChange={handleChange}
                className="h-100 rounded-0"
                required
              />
              <label htmlFor="email-input">{translations.emailAdrress}</label>
              <div className="show-email-tooltip">
                <p>{"translations.emailValidity"}</p>
              </div>
            </Form.Floating>
            <Form.Floating className="mb-4 rounded-0 request-phoneNumber">
              <Form.Control
                className="rounded-0 h-100"
                id="request-phone-number-input"
                type="tel"
                name="phoneNumber"
                placeholder="Phone Number"
                value={calcPhoneNumber}
                pattern="[0-9]{9,}"
                minLength="9"
                required
                onChange={handleChange}
              />
              <label htmlFor="phone-number-input" className="rounded-0">
                {translations.phoneNumber}
              </label>
            </Form.Floating>
            <Button
              className="py-3 request-submit-button"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Loading ..." : translations.submit}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CalcCauntPrice;
